<template>
    <div class="deposit-wrap">
        <van-field v-model.trim="formData.user_name" label="姓名" input-align="right" placeholder="请输入" />
        <van-field v-model="typeName" label="提现方式" @click="isType=true" input-align="right" readonly placeholder="请选择" right-icon="arrow"/>

        <div v-if="formData.type == 0">
            <van-field v-model.trim="formData.bank_name" label="提现银行" input-align="right" placeholder="请输入" />
            <van-field v-model.trim="formData.branch_bank_name" label="开户支行" input-align="right" placeholder="请输入" />
            <van-field v-model="address" label="开户所在地" @click="showPicker=true" input-align="right" readonly placeholder="请选择" right-icon="arrow"/>
            <van-field v-model.trim="formData.bank_card" label="卡号" input-align="right" placeholder="请输入" />
        </div>
        
        <van-field v-else v-model.trim="formData.bank_card" label="账号" input-align="right" placeholder="请输入" />
       
       
        <div class="money">可提现金额：￥{{money}}</div>
        <van-field v-model="money" label="提现金额" input-align="right" readonly placeholder="请输入" />
        <van-field v-model.trim="formData.remark" label="备注" input-align="right" placeholder="请输入" />

        <van-popup v-model.trim="showPicker" round position="bottom">
            <van-picker 
                show-toolbar 
                title="开户所在地" 
                value-key="name"
                :columns="columns" 
                @cancel="showPicker = false"
                @confirm="onConfirm"
                @change="changeAddres"
            />
        </van-popup>
        
        <van-popup v-model.trim="isType" round position="bottom">
            <van-picker 
                show-toolbar 
                title="提现方式" 
                value-key="name"
                :columns="withdrawType" 
                @cancel="isType = false"
                @confirm="onConfirmType"
            />
        </van-popup>

        <div class="footer">
            <div @click="submint">确定</div>
        </div>
    </div>
</template>

<script>
    import { validaCart, validaZH, validaAlipay } from '@/utils/valida'
    export default {
        data() {
            return {
                formData: {
                    user_name: '',
                    bank_name: '',
                    branch_bank_name: '',
                    bank_card: '',
                    remark: '',
                    province: '',
                    city: '',
                    type: ''
                },
                validaKey: [
                    {
                        msg: '请输入中文姓名',
                        key: 'user_name'
                    },
                    {
                        msg: '提现银行请输入中文',
                        key: 'bank_name'
                    },
                    {
                        msg: '开户所在地请输入中文',
                        key: 'branch_bank_name'
                    },
                    {
                        msg: '请输入正确的卡号',
                        key: 'bank_card'
                    }
                ],
                zfbKey: [
                    {
                        msg: '请输入中文姓名',
                        key: 'user_name'
                    },
                    {
                        msg: '请输入账号',
                        key: 'bank_card'
                    }
                ],
                address: '',
                money: 0,
               columns: [
                    {
                        values: [],
                        defaultIndex: 0
                    },
                    {
                        values: [],
                        defaultIndex: 0
                    }
                ],
                withdrawType: [], //提现类型
                showPicker: false,
                isOne: true,
                isClick: false,
                isType: false,
                typeName: ''
            }
        },
        created(){
            this.money = sessionStorage.getItem('money')
            this.getAddress()
            this.getWithdrawType()
        },
        methods: {
            onConfirm(value) {
                this.formData.province = value[0].code
                this.formData.city = value[1].code
                this.address = `${value[0].name}${value[1].name}`
                this.showPicker = false
            },
            changeAddres(e, value, index){
                if(index == 0) {
                    this.getAddress(value[0].code)
                }
            },
            onConfirmType({ id, name }) {
                this.isType = false
                if(this.formData.type == id) return
                for (const key in this.formData) {
                    this.formData[key] = ''
                }
                this.address = ''
                this.formData.type = id
                this.typeName = name
            },
            //提现类型
            getWithdrawType() {
                this.$getHttp(this.$api.getWithdrawType).then(res => {
                    this.withdrawType = res.data
                    this.formData.type = res.data[0].id
                    this.typeName = res.data[0].name
                })
            },
            //提交
            submint(){
                const { validaKey, formData, zfbKey } = this
                let postFormData = { ...formData}
                const otherType = [ 'bank_name', 'branch_bank_name', 'province', 'city']
                if(this.isClick) return
                // 0 银行卡
                if(formData.type == 0) {
                    for (let i = 0; i < validaKey.length; i++) {
                        if(validaKey[i].key == 'bank_card' ) {
                            if(!validaCart(formData[validaKey[i].key])){
                                this.$toast(validaKey[i].msg)
                                return
                            }
                        } else if(!validaZH(formData[validaKey[i].key])) {
                            this.$toast(validaKey[i].msg)
                            return
                        }
                    }
                    if(!validaZH(this.address)){
                        this.$toast('请选择开户所在地址')
                        return
                    }
                } else {
                    for (let i = 0; i < zfbKey.length; i++) {
                        if(zfbKey[i].key == 'bank_card' ) {
                            if(!validaAlipay(formData[zfbKey[i].key])){
                                this.$toast(zfbKey[i].msg)
                                return
                            }
                        } else if(!validaZH(formData[zfbKey[i].key])) {
                            this.$toast(zfbKey[i].msg)
                            return
                        }
                    }
                    otherType.forEach(item => {
                        delete postFormData[item]
                    })
                }
                this.isClick = true
                this.$postHttp(this.$api.postApplyWithraw, postFormData).then(res => {
                    if(res.code == 1) {
                        this.$toast(res.msg)
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 800);
                    }
                    this.isClick = false
                }).catch(()=>{
                    this.isClick = false
                })
            },
            getAddress(code=0) {
                this.$getHttp(this.$api.getAddress, { code }).then(res=>{
                    const { data }= res
                    if(data && data.length) {
                        if(code ==0){
                            this.columns[0].values = data
                        } else {
                            this.columns[1].values = data
                        }
                        this.isOne && this.getAddress(data[0].code)
                        this.isOne = false
                    }
                   
                })
            }
        }
    }
</script>
<style>
html,body,#app{
    height: 100%;
}
</style>
<style lang="scss" scoped>
.deposit-wrap{
    height: 100%;
    background: #F9FAFE;
    ::v-deep .van-cell{
        height: r(92);
        &::after{
            left: 0;
            right: 0;
        }
        .van-field__label{
            @include font(r(28),#697085)
        }
    }
    .money{
        @include font(r(24),#F1524D);
        margin: r(40) 0 r(16) r(34);
    }
    .footer{
        width: 100%;
        height: r(144);
        position: fixed;
        left: 0;
        bottom: 0;
        box-shadow: 0px r(6) r(6) rgba(104, 109, 136, 0.05);
        background: #fff;
        div{
            margin: auto;
            margin-top: r(32);
            @include wh(r(670),r(80));
            border-radius: r(8);
            background: $main_color;
            line-height: r(80);
            @include font(r(32),#fff,center)
            
        }
    }
}
</style>